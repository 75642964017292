<template>
  <div v-if="isSuperUser()">
    <div v-if="loading" class="row">
      <div class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12 offset-sm-3 col-sm-6 offset-lg-4 col-lg-4">
        <form>
          <div class="form-group">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="radioTelematic" value=2 v-model.number="devparams.device_type" checked>
              <label class="form-check-label" for="radioTelematic">Telematic device</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="radioBeacon" value=3 v-model.number="devparams.device_type">
              <label class="form-check-label" for="radioBeacon">Beacon</label>
            </div>
          </div>
          <div class="form-group">
            <label for="devsubtype">Subtype</label>
            <select class="form-control" id="devsubtype" v-model.number="devparams.device_subtype" @change="updateType">
               <option v-for="t in types.filter(t => t.type == devparams.device_type)" :value="t.subtype" :key="t.subtype">
                  {{t.description}}
                </option>
            </select>
          </div>
          <div class="form-group autocomplete">
            <label for="devpro">Project</label>
            <input type="text" class="form-control" id="devpro" autocomplete="off" placeholder="Project" v-model="searchProject" @input="onChange"/>
              <ul class="autocomplete-results" v-show="isSearching">
                <li class="autocomplete-result" v-for="p in results.slice(0,10)" :key="p.id" @click="setProject(p)">{{p.name}} ({{p.pro_id}})</li>
              </ul>
          </div>
        </form>
          <div v-if="(devparams.device_type == 2)">
            <div class="form-group">
              <label for="uc">Usecase</label>
              <select class="form-control" id="uc" v-model.number="uc.usecase">
                <option value=0>No usecase</option>
                <option v-for="u in usecases" :value="u.up_id" :key="u.up_id">
                    {{u.up_id}}: {{u.name}} ({{u.description}})
                  </option>
              </select>
            </div>
            <div class="form-group">
              <label for="imei">IMEI</label>
              <input type="text" class="form-control" id="imei" placeholder="IMEI" v-model="devparams.imei" @input="validateIMEI"/>
            </div>
            <div class="form-group">
              <label for="serial">Serial</label>
              <input type="text" class="form-control" id="serial" placeholder="Serial number" v-model="devparams.serial" :disabled="devparams.device_subtype != 3"/>
            </div>
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" class="form-control" id="name" placeholder="Device name" v-model="devparams.device_name"/>
            </div>
            <div class="text-center">
              <button class="btn btn-primary m-1" @click="validateDevice()">Create Device</button>
            </div>
        </div>
        <div v-else>
            <div class="form-group">
              <label for="namespace">Namespace</label>
              <input type="text" class="form-control" id="namespace" placeholder="Namespace" minlength="20" maxlength="20" v-model="namespace"/>
            </div>
            <div class="form-group">
              <label for="instance">Instance</label>
              <input type="text" class="form-control" id="Instance" placeholder="Instance" minlength="12" maxlength="12" v-model="instance"/>
            </div>
            <div class="text-center">
              <button class="btn btn-primary m-1" @click="validateBeacon()">Create Beacon</button>
            </div>
        </div>
      </div>
    </div>
    <div class="col-12 offset-sm-3 col-sm-6 offset-lg-4 col-lg-4 history-div">
      <div v-for="h in history" :key="h.str">{{h.str}}
        <a v-if="h.link != null" :href="'/device/' + h.link"> {{h.link}}</a>
      </div>
    </div>
  </div>
  <div v-else>
    You cannot access this page
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import axios from '@/axios-auth.js'

export default {
  data () {
    return {
      loading: true,
      isSearching: false,
      searchProject: '',
      results: [],
      devparams: {
        device_type: 2,
        device_subtype: null,
        project_id: null,
        reseller_id: null,
        serial: null,
        imei: null,
        device_name: null
      },
      uc: {
        usecase: null
      },
      namespace: null,
      instance: null,
      usecases: [],
      history: []
    }
  },
  methods: {
    isSuperUser () {
      return (localStorage.getItem('role') === 'super_user')
    },
    handleAPIresponse (e) {
      if (!e.response) {
        EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
      } else if (e.response.status === 401) {
        EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
        this.$router.push({ name: 'login' })
      } else {
        EventBus.$emit('alert', 'error', 'A server error occured while loading the projects.')
      }
    },
    addHistory (s, serial) {
      var current = new Date()
      var h = {}
      h.str = '[' + current.toLocaleString() + '] ' + s
      h.link = serial
      this.history.push(h)
    },
    loadProjects () {
      axios.get('/projects')
        .then((response) => {
          this.projects = response.data.map(p => ({ ...p, selected: false }))
        })
        .catch((e) => {
          this.handleAPIresponse(e)
        })
    },
    loadTypes () {
      axios.get('/device/types')
        .then((response) => {
          this.types = response.data
        })
        .catch((e) => {
          this.handleAPIresponse(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadUsecases () {
      axios.get('/project/' + this.devparams.project_id + '/usecases')
        .then((response) => {
          this.usecases = response.data
        })
        .catch((e) => {
          this.handleAPIresponse(e)
        })
    },
    onChange () {
      this.results = this.projects.filter(pro => pro.name.toLowerCase().indexOf(this.searchProject.toLowerCase()) > -1)
      this.isSearching = true
    },
    updateType () {
      if (this.devparams.device_subtype === 3) {
        // calamp special handling
        this.devparams.profile_id = 224
      } else if (this.devparams.device_subtype > 3) {
        // teltonika handling
        this.devparams.profile_id = 225
      } else {
        this.devparams.profile_id = 0
      }
    },
    setProject (project) {
      this.devparams.project_id = project.pro_id
      this.devparams.reseller_id = project.res_id
      this.searchProject = project.name + ' (' + project.pro_id + ')'
      this.isSearching = false
      this.loadUsecases()
    },
    validateIMEI () {
      if (isNaN(this.devparams.imei)) {
        this.devparams.imei = this.devparams.imei.match(/\d{15}/)[0]
      }

      this.devparams.device_name = this.devparams.imei
      if (this.devparams.device_subtype !== 3) {
        this.devparams.serial = this.devparams.imei // update serial only for devices other than calamp
      }
    },
    validateDevice () {
      if (this.devparams.device_type !== 2) {
        EventBus.$emit('alert', 'error', 'Invalid type.')
        return
      }

      if (this.devparams.device_subtype == null) {
        EventBus.$emit('alert', 'error', 'Please select a subtype.')
        return
      }

      if (this.devparams.project_id == null) {
        EventBus.$emit('alert', 'error', 'Please select a project.')
        return
      }

      if ((this.devparams.imei == null) || (this.devparams.imei.length !== 15) || (isNaN(this.devparams.imei))) {
        EventBus.$emit('alert', 'error', 'Invalid IMEI.')
        return
      }

      if ((this.devparams.serial == null) || (isNaN(this.devparams.serial))) {
        EventBus.$emit('alert', 'error', 'Invalid serial.')
        return
      }

      this.createDevice().then(resolve => this.setUsecase())
    },
    validateBeacon () {
      if (this.devparams.device_type !== 3) {
        EventBus.$emit('alert', 'error', 'Invalid type.')
        return
      }

      if (this.devparams.device_subtype == null) {
        EventBus.$emit('alert', 'error', 'Please select a subtype.')
        return
      }

      if (this.devparams.project_id == null) {
        EventBus.$emit('alert', 'error', 'Please select a project.')
        return
      }

      // namespace must be hexadecimal string without :
      const hexregexp = /^[0-9a-fA-F]+$/
      if ((!hexregexp.test(this.namespace)) || (this.namespace.length !== 20)) {
        EventBus.$emit('alert', 'error', 'Invalid namespace.')
        return
      }

      if ((!hexregexp.test(this.instance)) || (this.instance.length !== 12)) {
        EventBus.$emit('alert', 'error', 'Invalid instance.')
        return
      }

      // prepare device params for creation
      this.devparams.imei = ''
      this.devparams.serial = this.namespace + ':' + this.instance
      this.devparams.device_name = 'Tag ' + this.instance.substring(6)
      this.createDevice()
    },
    createDevice () {
      return new Promise((resolve) => {
        axios.post('/device', this.devparams)
          .then((response) => {
            // device succesfully created
            this.addHistory('Created device ', this.devparams.serial)
            resolve()
          })
          .catch((e) => {
            if ((e.response.status === 400) && (e.response.data.message.toLowerCase().includes('already exist'))) {
              if (confirm('Device already exists. Do you want to delete it ?')) {
                // delete existing device and recreate it
                this.deleteDevice(this.devparams.serial).then(res1 => this.createDevice()).then(res2 => resolve())
              }
            } else {
              this.handleAPIresponse(e)
            }
          })
      })
    },
    deleteDevice (serial) {
      return new Promise((resolve) => {
        axios.delete('/device/' + serial)
          .then((response) => {
            this.addHistory('Deleted device ' + serial, null)
            resolve()
          })
          .catch((e) => {
            this.handleAPIresponse(e)
          })
      })
    },
    setUsecase () {
      axios.put('/device/' + this.devparams.serial + '/usecase', this.uc)
        .then((response) => {
        })
        .catch((e) => {
          this.handleAPIresponse(e)
        })
    }
  },
  mounted () {
    this.loadTypes()
    this.loadProjects()
  },
  components: {
    LoadingAnimation
  }
}
</script>
<style>
  .autocomplete {
    position: relative;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    position: absolute;
    z-index: 99;
    background-color: #fff;
    top: 100%;
    left: 0;
    right: 0;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
    border-bottom: 1px solid #d4d4d4;
  }

  .autocomplete-result:hover {
    background-color: #4781ec;
    color: white;
  }

  .history-div {
    overflow-y: scroll;
    max-height: 180px;
    word-wrap:break-word;
    background-color: #d4d4d4f3;
  }
</style>
