<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-6 col-sm-12 mb-2">
        <h1>{{ details.name }}</h1>
        <p><b>Dev Id</b> : {{ details.id }}</p>
        <p><b>Serial</b> : {{ details.serial }}</p>
        <p><b>IMEI</b> : {{ details.imei }}</p>
        <p><b>Reference</b> : {{ details.user_ref }}</p>
        <p><b>Last heard</b> : {{ new Date(details.last_heard).toLocaleString() }} ({{ lastHeardTimeSince }})</p>
        <p><b>Last Position</b> : {{ new Date(details.position.date).toLocaleString() }} ({{ positionTimeSince }})</p>
        <p><b>Status</b> : <span :class="{'text-danger': details.status === 'OFFLINE'}">{{ details.status }}</span></p>
        <p><b>Current profile</b> : {{ details.current_profile.name }} ({{ details.current_profile.description }})</p>
        <p><b>Project</b> : {{ details.project_name }}</p>
        <p><b>Software Version</b> : {{ details.version }}</p>
        <p v-if="details.dota_in_progress != null" class="text-warning"><b>DOTA Planned</b> : {{ details.dota_in_progress }}</p>
        <p><b>Model</b> : {{ details.model }}</p>
        <p><b>Install Date</b> : {{ new Date(details.install_date).toLocaleString() }}</p>
        <p><b>Active</b> : {{ details.active ? 'yes' : 'no' }}</p>
        <div class="text-center">
          <button class="btn btn-success mx-1 my-1" @click="$router.push({ name: 'deviceConfig' })">Config ⚙️</button>
          <button class="btn btn-primary mx-1 my-1" @click="loadDeviceDetails()">Refresh 🔄</button>
          <button :class="['btn auto-refresh-btn mx-1 my-1', autoRefreshBtnType]" @click="toggleAutoRefresh()">{{ autoRefreshBtnValue }}</button>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 mb-2">
        <Map class="map-container" :lat="details.position.lat" :lon="details.position.lon"/>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import axios from '@/axios-auth.js'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import Map from '@/components/Map.vue'
import { format } from 'timeago.js'

export default {
  data () {
    return {
      loading: true,
      details: {},
      autoRefreshInterval: null,
      autoRefreshBtnType: 'btn-secondary',
      autoRefreshBtnValue: 'Enable auto refresh',
      serial: ''
    }
  },
  methods: {
    loadDeviceDetails () {
      this.loading = true
      axios.get('/device/' + this.serial)
        .then((response) => {
          this.details = response.data
          // cache device name, serial and model so that it can be reused by other views
          sessionStorage.setItem('serial', this.details.serial)
          sessionStorage.setItem('deviceName', this.details.name)
          sessionStorage.setItem('deviceModel', this.details.model)
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else if (e.response.status === 404) {
            EventBus.$emit('alert', 'error', 'The server couldn\'t find the device details for serial ' + this.serial)
            this.$router.push({ name: 'deviceSelection' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the device details. Please try again later.')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    startAutoRefresh () {
      this.autoRefreshInterval = setInterval(() => { this.loadDeviceDetails() }, 60 * 1000)
      this.autoRefreshBtnType = 'btn-primary'
      this.autoRefreshBtnValue = 'Disable auto refresh'
    },
    stopAutoRefresh () {
      if (this.autoRefreshInterval !== null) {
        clearInterval(this.autoRefreshInterval)
        this.autoRefreshInterval = null
      }
      this.autoRefreshBtnType = 'btn-secondary'
      this.autoRefreshBtnValue = 'Enable auto refresh'
    },
    toggleAutoRefresh () {
      if (!this.autoRefreshInterval) {
        this.startAutoRefresh()
      } else {
        this.stopAutoRefresh()
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    // clean up auto refresh when changing views
    this.stopAutoRefresh()
    next()
  },
  computed: {
    lastHeardTimeSince () {
      return format(this.details.last_heard)
    },
    positionTimeSince () {
      return format(this.details.position.date)
    }
  },
  mounted () {
    this.serial = this.$route.params.serial
    // emit the serial in case it was set by the user in the url
    EventBus.$emit('serial', this.serial)
    this.loadDeviceDetails()
  },
  components: {
    LoadingAnimation,
    Map
  }
}
</script>

<style scoped>
.map-container {
  height: 90vh;
}
.auto-refresh-btn {
  width: 180px;
}
</style>
