<template>
  <div>
    <ViewHeader :serial="$route.params.serial" :hideDatePicker="true"/>
    <configTabs/>
    <div v-if="loading" class="row">
      <div class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-sm-6 mb-2">
        <h3>Main Project</h3>
        <table class="table">
          <tbody>
            <tr>
              <th>{{ projects.main_project.name }} ⭐</th>
              <td class="text-center">{{ projects.main_project.pro_id }}</td>
            </tr>
          </tbody>
        </table>
        <h3>Device Projects</h3>
        <table class="table">
          <tbody>
            <tr v-for="project in projects.device_projects" :key="project.pro_id">
              <th>{{ project.name }}</th>
              <td class="text-center">{{ project.pro_id }}</td>
              <td class="text-center"><button class="btn btn-primary" title="Set as main project" @click="setMainProject(project)">⭐</button></td>
              <td class="text-right"><button class="btn btn-danger" title="Remove from device projects" @click="removeDeviceProject(project)"><b>x</b></button></td>
            </tr>
          </tbody>
        </table>
        <div class="text-center">
          <button class="btn btn-success mt-2" @click="saveProjects()">Save projects 💾</button>
        </div>
      </div>
      <div class="col-sm-6 mb-2">
        <h3>Available Projects</h3>
        <div class="input-group mb-2">
            <input type="text" placeholder="filter projects" class="form-control" v-model="filterText">
        </div>
        <table class="table">
          <tbody>
            <tr v-for="project in filteredProjects" :key="project.pro_id">
              <th>{{ project.name }}</th>
              <td class="text-center">{{ project.pro_id }}</td>
              <td class="text-right"><button class="btn btn-primary" title="Add to device projects" @click="addDeviceProject(project)"><b>+</b></button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import ViewHeader from '@/components/ViewHeader.vue'
import axios from '@/axios-auth.js'
import ConfigTabs from '@/components/ConfigTabs.vue'

export default {
  data () {
    return {
      loading: true,
      projects: {
        main_project: {},
        device_projects: [],
        available_projects: []
      },
      ogProjects: {
        main_project: {},
        device_projects: []
      },
      filterText: '',
      serial: ''
    }
  },
  methods: {
    loadDeviceProjects () {
      this.loading = true
      axios.get('/device/' + this.serial + '/projects')
        .then((response) => {
          this.projects.main_project = response.data.main_project
          this.projects.device_projects = response.data.device_projects
          // save original projects from response
          this.ogProjects.main_project = this.projects.main_project.pro_id
          this.ogProjects.device_projects = this.projects.device_projects.map(p => p.pro_id)
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else if (e.response.status === 404) {
            EventBus.$emit('alert', 'error', 'The server couldn\'t find the device projects for serial ' + this.serial)
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the device projects. Please try again later.')
          }
        })
        .finally(() => {
          this.loadProjects()
        })
    },
    loadProjects () {
      axios.get('/projects')
        .then((response) => {
          // remove current projects from projects list to get availables
          this.projects.available_projects = response.data.filter(p => !this.ogProjects.device_projects.includes(p.pro_id))
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the projects. Please try again later.')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    sortProjects (projectsArray) {
      projectsArray.sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
        if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
        return 0
      })
    },
    setMainProject (project) {
      this.projects.main_project = project
    },
    removeDeviceProject (project) {
      if (project.pro_id === this.projects.main_project.pro_id) {
        EventBus.$emit('alert', 'info', 'You have to first choose another main project if you want to remove this device project.')
        return
      }
      this.projects.device_projects.splice(this.projects.device_projects.indexOf(project), 1)
      this.projects.available_projects.push(project)
      this.sortProjects(this.projects.available_projects)
    },
    addDeviceProject (project) {
      this.projects.available_projects.splice(this.projects.available_projects.indexOf(project), 1)
      this.projects.device_projects.push(project)
      this.sortProjects(this.projects.device_projects)
    },
    saveProjects () {
      const payload = {}
      payload.main_project = this.projects.main_project.pro_id
      payload.device_projects = this.projects.device_projects.map(p => p.pro_id)
      // check if anything has actually changed
      if (!this.projectsChanged(payload)) {
        return
      }
      this.loading = true
      axios.put('/device/' + this.serial + '/projects', payload)
        .then((response) => {
          // payload was successfully saved so it became our original projects
          this.ogProjects = payload
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while saving the device projects. Please try again later.')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    projectsChanged (payload) {
      if (payload.main_project !== this.ogProjects.main_project) {
        return true
      }
      if (payload.device_projects.length !== this.ogProjects.device_projects.length) {
        return true
      }
      for (let i = 0; i < payload.device_projects.length; i++) {
        if (!this.ogProjects.device_projects.includes(payload.device_projects[i])) {
          return true
        }
      }
      return false
    },
    isSuperUser () {
      return (localStorage.getItem('role') === 'super_user')
    }
  },
  computed: {
    filteredProjects () {
      if (this.filterText.trim === '') {
        return this.projects.available_projects
      }
      return this.projects.available_projects.filter(p => {
        // filter on case insensitive project name or project id
        return p.name.toUpperCase().includes(this.filterText.toUpperCase()) || p.pro_id.toString().includes(this.filterText)
      })
    }
  },
  mounted () {
    this.serial = this.$route.params.serial
    // emit the serial in case it was set by the user in the url
    EventBus.$emit('serial', this.serial)
    this.loadDeviceProjects()
  },
  components: {
    LoadingAnimation,
    ViewHeader,
    ConfigTabs
  }
}
</script>
