<template>
  <div>
    <ViewHeader :serial="$route.params.serial" @picker="pickerEventHandler" range="week"/>
    <div v-if="loading" class="row">
      <div class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
    </div>
    <div v-else>
      <div v-if="noData" class="row">
        <div class="col-12 align-self-center text-center no-data">
          <h1>No data ¯\_(ツ)_/¯ </h1>
          <h3 class="text-secondary"> {{ this.serial }}</h3>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12 mb-2">
          <h3 id="hdop"><a href="#hdop">HDOP</a></h3>
          <p>1 is best</p>
          <BarChart :chart-data="values.hdop" :chart-labels="labels.hdop" chart-title="HDOP"/>
        </div>
        <div class="col-12 mb-2">
          <h3 id="snr"><a href="#snr">Signal To Noise Ratio (SNR)</a></h3>
          <p>Higher is better</p>
          <BarChart :chart-data="values.snr" :chart-labels="labels.snr" chart-title="Best SNR"/>
        </div>
        <div class="col-12 mb-2">
          <h3 id="satsseen"><a href="#satsseen">Satellites seen</a></h3>
          <BarChart :chart-data="values.sats" :chart-labels="labels.sats" chart-title="Sats seen"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import BarChart from '@/components/BarChart.vue'
import ViewHeader from '@/components/ViewHeader.vue'
import axios from '@/axios-auth.js'

export default {
  data () {
    return {
      loading: true,
      stats: {},
      labels: {},
      values: {},
      serial: '',
      noData: false,
      from: null,
      to: null
    }
  },
  methods: {
    loadGpsStats () {
      this.loading = true
      axios.get('/device/' + this.serial + '/gpsstats', {
        params: {
          from: this.from,
          to: this.to,
          max: 100
        }
      })
        .then((response) => {
          this.stats = response.data
          this.organizeChartData('hdop', 'hdop', 'value')
          this.organizeChartData('signal_stats', 'snr', 'best_snr')
          this.organizeChartData('signal_stats', 'sats', 'sats_seen')
          this.noData = false
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else if (e.response.status === 404) {
            this.noData = true
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the gps stats. Please try again later.')
            this.$router.push({ name: 'deviceSelection' })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    organizeChartData (property, metric, valueName) {
      this.values[metric] = []
      this.labels[metric] = []
      const l = this.stats[property].length
      for (let i = 0; i < l; i++) {
        // reverse order while organizing
        this.values[metric][l - 1 - i] = this.stats[property][i][valueName]
        this.labels[metric][l - 1 - i] = new Date(this.stats[property][i].date).toLocaleString()
      }
    },
    pickerEventHandler (from, to) {
      this.from = from
      this.to = to
      this.loadGpsStats()
    }
  },
  mounted () {
    this.serial = this.$route.params.serial
    // emit the serial in case it was set by the user in the url
    EventBus.$emit('serial', this.serial)
  },
  components: {
    LoadingAnimation,
    BarChart,
    ViewHeader
  }
}
</script>

<style scoped>
.no-data {
  margin-top: 20vh;
}
</style>
