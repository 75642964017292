<template>
  <div class="row">
    <div class="col-12 mb-2">
      <button :class="[sharedStyle, isActive('deviceConfig')]" @click="goTo('deviceConfig')">Configuration</button>
      <button :class="[sharedStyle, isActive('deviceUsecases')]" @click="goTo('deviceUsecases')">Usecases</button>
      <button :class="[sharedStyle, isActive('deviceProjects')]" @click="goTo('deviceProjects')">Projects</button>
      <button :class="[sharedStyle, isActive('bleSensors')]" @click="goTo('bleSensors')">BLE Sensors</button>
      <button v-if="isSuperUser()" :class="[sharedStyle, isActive('deviceResellers')]" @click="goTo('deviceResellers')">Resellers</button>
      <button v-if="isTeltonika()" :class="[sharedStyle, isActive('gprsCommands')]" @click="goTo('gprsCommands')">GPRS Commands</button>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      sharedStyle: 'btn mr-1 my-1'
    }
  },
  methods: {
    goTo (route) {
      this.$router.push({ name: route })
    },
    isActive (route) {
      return this.$route.name === route ? 'btn-primary' : 'btn-secondary'
    },
    isSuperUser () {
      return (localStorage.getItem('role') === 'super_user')
    },
    isTeltonika () {
      return (sessionStorage.getItem('deviceModel').toLowerCase().indexOf('teltonika') >= 0)
    }
  }
}
</script>
