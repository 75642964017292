<template>
  <div class="table-responsive">
    <table class="table table-striped">
      <thead class="thead-light">
        <tr>
          <th v-if="hasCardField" scope="col">Driver card</th>
          <th scope="col">File name</th>
          <th scope="col">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="download in downloads" :key="download.date">
          <td v-if="hasCardField">{{ download.driver_card }}</td>
          <td>{{ download.file_name }}</td>
          <td>{{ new Date(download.date).toLocaleString() }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    hasCardField: {
      type: Boolean
    },
    downloads: {
      type: Array
    }
  }
}
</script>
