<script>
import { Bar } from 'vue-chartjs'
export default {
  extends: Bar,
  props: {
    chartData: {
      type: Array,
      required: false
    },
    chartLabels: {
      type: Array,
      required: true
    },
    chartTitle: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      options: {
        legend: {
          display: false
        },
        title: {
          display: false,
          text: ''
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    if (this.chartTitle !== '') {
      this.options.title.display = true
      this.options.title.text = this.chartTitle
    }
    this.renderChart({
      labels: this.chartLabels,
      datasets: [
        {
          label: 'data',
          backgroundColor: '#3498db',
          data: this.chartData
        }
      ]
    }, this.options)
  }
}
</script>
