<template>
  <div>
    <ViewHeader :serial="$route.params.serial" @picker="pickerEventHandler" range="today"/>
    <div v-if="loading" class="row">
      <div class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
    </div>
    <div v-else>
      <div v-if="noData" class="row">
        <div class="col-12 align-self-center text-center no-data">
          <h1>No data ¯\_(ツ)_/¯ </h1>
          <h3 class="text-secondary"> {{ this.serial }}</h3>
        </div>
      </div>
      <div v-else class="row mb-4">
        <div class="col-12">
          <LineChart :chart-data="values" :chart-title="fuelLevelUnit !== '' ? 'Fuel level (' + fuelLevelUnit + ')' : ''"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p><b>Latest fuel level</b> : {{ latestFuelLevel ? latestFuelLevelStr : 'No data' }}</p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-sm-6 mb-2">
          <h3>Fuel Tank Model</h3>
          <table class="table">
            <tbody>
              <tr>
                <th>{{ fuelTanks.fuelTank.model }} ⭐</th>
                <td class="text-center">{{ fuelTanks.fuelTank.tank_id }}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-center">
            <button class="btn btn-success mt-2" @click="saveFuelTank()">Save fuel tank model 💾</button>
          </div>
        </div>
        <div class="col-sm-6 mb-2">
          <h3>Available Fuel Tank Models</h3>
          <div class="input-group mb-2">
              <input type="text" placeholder="filter fuel tank models" class="form-control" v-model="filterText">
          </div>
          <table class="table">
            <tbody>
              <tr v-for="fuelTank in filteredFuelTanks" :key="fuelTank.res_id">
                <th>{{ fuelTank.model }}</th>
                <td class="text-center">{{ fuelTank.tank_id }}</td>
                <td class="text-right"><button class="btn btn-primary" title="Set as fuel tank model" @click="setFuelTank(fuelTank)">⭐</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import LineChart from '@/components/LineChart.vue'
import ViewHeader from '@/components/ViewHeader.vue'
import axios from '@/axios-auth.js'
import { format } from 'timeago.js'

export default {
  data () {
    return {
      loading: true,
      fuelLevels: {},
      fuelLevelUnit: '',
      latestFuelLevel: null,
      fuelTanks: {
        fuelTank: {},
        available_fuelTanks: []
      },
      ogFuelTank: 0,
      filterText: '',
      values: [],
      serial: '',
      noData: false,
      from: null,
      to: null
    }
  },
  methods: {
    loadFuelLevels () {
      this.loading = true
      axios.get('/device/' + this.serial + '/fuellevels', {
        params: {
          from: this.from,
          to: this.to,
          max: 100
        }
      })
        .then((response) => {
          this.fuelLevels = response.data.data
          this.fuelLevelUnit = response.data.unit
          this.values = []
          this.organizeChartData()
          this.noData = false
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ model: 'login' })
          } else if (e.response.status === 404) {
            this.noData = true
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the fuel levels. Please try again later.')
            this.$router.push({ model: 'deviceSelection' })
          }
        })
        .finally(() => {
          this.loadDeviceFuelTank()
        })
    },
    loadDeviceFuelTank (loadThisOnly) {
      this.loading = true
      axios.get('/device/' + this.serial + '/fuel/tank')
        .then((response) => {
          this.fuelTanks.fuelTank = response.data.fuel_tank
          // save original fuelTank from response
          this.ogFuelTank = this.fuelTanks.fuelTank.tank_id
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later...')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ model: 'login' })
          } else if (e.response.status === 404) {
            this.fuelTanks.fuelTank = { model: 'No fuel tank', tank_id: 0 }
            this.ogFuelTank = 0
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the device fuel tank model. Please try again later.')
          }
        })
        .finally(() => {
          if (loadThisOnly) {
            this.loading = false
          } else {
            this.loadFuelTanks(true)
          }
        })
    },
    loadFuelTanks () {
      axios.get('/fuel/tanks')
        .then((response) => {
          // remove current fuelTank from fuelTanks list to get availables
          this.fuelTanks.available_fuelTanks = response.data.filter(f => f.tank_id !== this.ogFuelTank)
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ model: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading fuel tank models. Please try again later.')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadLatestFuelLevel () {
      axios.get('/device/' + this.serial + '/fuellevels', {
        params: {
          latest: true
        }
      })
        .then((response) => {
          this.latestFuelLevel = response.data.data[0]
        })
        .catch((e) => {
          this.latestFuelLevel = null
          if (e.response && e.response.status !== 404) {
            EventBus.$emit('alert', 'error', 'Failed to load latest fuel level.')
          }
        })
    },
    sortFuelTanks (fuelTanksArray) {
      fuelTanksArray.sort((a, b) => {
        if (a.model.toUpperCase() < b.model.toUpperCase()) return -1
        if (a.model.toUpperCase() > b.model.toUpperCase()) return 1
        return 0
      })
    },
    setFuelTank (fuelTank) {
      // put old fuelTank back in available
      this.fuelTanks.available_fuelTanks.push(this.fuelTanks.fuelTank)
      // set new fuelTank
      this.fuelTanks.fuelTank = fuelTank
      // remove new fuelTank from available
      this.fuelTanks.available_fuelTanks = this.fuelTanks.available_fuelTanks.filter(f => f.tank_id !== fuelTank.tank_id)
      // sort modified available
      this.sortFuelTanks(this.fuelTanks.available_fuelTanks)
    },
    saveFuelTank () {
      // check if anything has actually changed
      if (this.fuelTanks.fuelTank.tank_id === this.ogFuelTank) {
        return
      }
      const payload = {}
      payload.fuel_tank = this.fuelTanks.fuelTank.tank_id

      this.loading = true
      axios.put('/device/' + this.serial + '/fuel/tank', payload)
        .then((response) => {
          // payload was successfully saved so it became our original fuelTank
          this.ogFuelTank = payload.fuel_tank
          // reload the fuel levels so they are recomputed with the new calibration scale
          this.loadFuelLevels()
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ model: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while saving the device fuel tank model. Please try again later.')
          }
          this.loading = false
        })
    },
    organizeChartData () {
      const value = {
        label: 'fuel',
        borderColor: 'blue',
        fill: false,
        lineTension: 0,
        data: []
      }
      const l = this.fuelLevels.length
      for (let i = 0; i < l; i++) {
        // reverse order while organizing
        value.data[l - 1 - i] = {
          x: new Date(this.fuelLevels[i].date),
          y: this.fuelLevels[i].value
        }
      }
      this.values.push(value)
    },
    pickerEventHandler (from, to) {
      this.from = from
      this.to = to
      this.loadFuelLevels()
    }
  },
  computed: {
    filteredFuelTanks () {
      if (this.filterText.trim === '') {
        return this.fuelTanks.available_fuelTanks
      }
      return this.fuelTanks.available_fuelTanks.filter(p => {
        // filter on case insensitive fuelTank model or tank id
        return p.model.toUpperCase().includes(this.filterText.toUpperCase()) || p.tank_id.toString().includes(this.filterText)
      })
    },
    latestFuelLevelStr () {
      return this.latestFuelLevel.value + ' ' + this.fuelLevelUnit + ' , ' + new Date(this.latestFuelLevel.date).toLocaleString() + ' (' + format(this.latestFuelLevel.date) + ')'
    }
  },
  mounted () {
    this.serial = this.$route.params.serial
    // emit the serial in case it was set by the user in the url
    EventBus.$emit('serial', this.serial)
    this.loadLatestFuelLevel()
  },
  components: {
    LoadingAnimation,
    LineChart,
    ViewHeader
  }
}
</script>

<style scoped>
.no-data {
  margin-top: 20vh;
}
</style>
