<template>
  <div>
    <ViewHeader :serial="$route.params.serial" :hideDatePicker="true"/>
    <configTabs/>
    <div v-if="loading" class="row">
      <div class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-sm-6 mb-2">
        <h3>Reseller</h3>
        <table class="table">
          <tbody>
            <tr>
              <th>{{ resellers.reseller.name }} ⭐</th>
              <td class="text-center">{{ resellers.reseller.res_id }}</td>
            </tr>
          </tbody>
        </table>
        <div class="text-center">
          <button class="btn btn-success mt-2" @click="saveReseller()">Save reseller 💾</button>
        </div>
      </div>
      <div class="col-sm-6 mb-2">
        <h3>Available resellers</h3>
        <div class="input-group mb-2">
            <input type="text" placeholder="filter resellers" class="form-control" v-model="filterText">
        </div>
        <table class="table">
          <tbody>
            <tr v-for="reseller in filteredResellers" :key="reseller.res_id">
              <th>{{ reseller.name }}</th>
              <td class="text-center">{{ reseller.res_id }}</td>
              <td class="text-right"><button class="btn btn-primary" title="Set as reseller" @click="setReseller(reseller)">⭐</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import ViewHeader from '@/components/ViewHeader.vue'
import axios from '@/axios-auth.js'
import ConfigTabs from '@/components/ConfigTabs.vue'

export default {
  data () {
    return {
      loading: true,
      resellers: {
        reseller: {},
        available_resellers: []
      },
      ogReseller: 0,
      filterText: '',
      serial: ''
    }
  },
  methods: {
    loadDeviceReseller () {
      this.loading = true
      axios.get('/device/' + this.serial + '/reseller')
        .then((response) => {
          this.resellers.reseller = response.data.reseller
          // save original reseller from response
          this.ogReseller = this.resellers.reseller.res_id
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else if (e.response.status === 404) {
            EventBus.$emit('alert', 'error', 'The server couldn\'t find the reseller for serial ' + this.serial)
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the device reseller. Please try again later.')
          }
        })
        .finally(() => {
          this.loadResellers()
        })
    },
    loadResellers () {
      axios.get('/resellers')
        .then((response) => {
          // remove current reseller from resellers list to get availables
          this.resellers.available_resellers = response.data.filter(r => r.res_id !== this.ogReseller)
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading resellers. Please try again later.')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    sortResellers (resellersArray) {
      resellersArray.sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
        if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
        return 0
      })
    },
    setReseller (reseller) {
      // put old reseller back in available
      this.resellers.available_resellers.push(this.resellers.reseller)
      // set new reseller
      this.resellers.reseller = reseller
      // remove new reseller from available
      this.resellers.available_resellers = this.resellers.available_resellers.filter(r => r.res_id !== reseller.res_id)
      // sort modified available
      this.sortResellers(this.resellers.available_resellers)
    },
    saveReseller () {
      // check if anything has actually changed
      if (this.resellers.reseller.res_id === this.ogReseller) {
        return
      }
      const payload = {}
      payload.reseller = this.resellers.reseller.res_id

      this.loading = true
      axios.put('/device/' + this.serial + '/reseller', payload)
        .then((response) => {
          // payload was successfully saved so it became our original reseller
          this.ogReseller = payload.reseller
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while saving the device reseller. Please try again later.')
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    filteredResellers () {
      if (this.filterText.trim === '') {
        return this.resellers.available_resellers
      }
      return this.resellers.available_resellers.filter(p => {
        // filter on case insensitive reseller name or reseller id
        return p.name.toUpperCase().includes(this.filterText.toUpperCase()) || p.res_id.toString().includes(this.filterText)
      })
    }
  },
  mounted () {
    this.serial = this.$route.params.serial
    // emit the serial in case it was set by the user in the url
    EventBus.$emit('serial', this.serial)
    this.loadDeviceReseller()
  },
  components: {
    LoadingAnimation,
    ViewHeader,
    ConfigTabs
  }
}
</script>
