<template>
  <div class="table-responsive">
    <table class="table table-striped">
      <thead class="thead-light">
        <tr>
          <th scope="col">Driver card</th>
          <th scope="col">Working state</th>
          <th scope="col">Tacho time</th>
          <th scope="col">Time received</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="event in events" :key="event.datetime_tacho">
          <td>{{ event.driver_card }}</td>
          <td>{{ event.working_state }}</td>
          <td>{{ new Date(event.datetime_tacho).toLocaleString() }}</td>
          <td>{{ new Date(event.date).toLocaleString() }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    events: {
      type: Array
    }
  }
}
</script>
