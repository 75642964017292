<template>
  <div>
    <ViewHeader :serial="$route.params.serial" @picker="pickerEventHandler" range="today"/>
    <div v-if="loading" class="row">
      <div class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
    </div>
    <div v-else>
      <div v-if="noData" class="row">
        <div class="col-12 align-self-center text-center no-data">
          <h1>No data ¯\_(ツ)_/¯ </h1>
          <h3 class="text-secondary"> {{ this.serial }}</h3>
          <p><i>Are you sure this device is connected to a tachograph's K-line ?</i></p>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-md-12 col-lg-6">
          <h3>Driver 1</h3>
          <TachoEventsTable :events="tachoEvents.driver1"/>
        </div>
        <div class="col-md-12 col-lg-6">
          <h3>Driver 2</h3>
          <TachoEventsTable :events="tachoEvents.driver2"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import TachoEventsTable from '@/components/TachoEventsTable.vue'
import ViewHeader from '@/components/ViewHeader.vue'
import axios from '@/axios-auth.js'

export default {
  data () {
    return {
      loading: true,
      tachoEvents: {},
      serial: '',
      noData: false,
      from: null,
      to: null
    }
  },
  methods: {
    loadTachoEvents () {
      this.loading = true
      axios.get('/device/' + this.serial + '/tachoevents', {
        params: {
          from: this.from,
          to: this.to,
          max: 100
        }
      })
        .then((response) => {
          this.tachoEvents = response.data
          this.noData = false
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else if (e.response.status === 404) {
            this.noData = true
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the tachograph events. Please try again later.')
            this.$router.push({ name: 'deviceSelection' })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    pickerEventHandler (from, to) {
      this.from = from
      this.to = to
      this.loadTachoEvents()
    }
  },
  mounted () {
    this.serial = this.$route.params.serial
    // emit the serial in case it was set by the user in the url
    EventBus.$emit('serial', this.serial)
  },
  components: {
    LoadingAnimation,
    TachoEventsTable,
    ViewHeader
  }
}
</script>

<style scoped>
.no-data {
  margin-top: 20vh;
}
</style>
