<template>
  <div id="app" class="container-fluid">
    <header>
      <nav v-if="displayNav" class="navbar navbar-expand-lg navbar-light fixed-top bg-light">
        <a class="navbar-brand" href="/"><img src="@/assets/ubidata-horizontal.png" alt="Ubidata logo" class="logo"></a>
        <button class="navbar-toggler mb-2" data-toggle="collapse" data-target="#navbarCollapse">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav flex-wrap mr-auto">
            <router-link :to="{ name: 'deviceSelection' }" tag="li" class="nav-item">
              <a class="nav-link text-center px-3">Search devices</a>
            </router-link>
            <router-link :to="{ name: 'deviceDetails', params: { serial: this.serial } }" tag="li" class="nav-item" exact>
              <a class="nav-link px-3 text-center">Device</a>
            </router-link>
            <router-link :to="{ name: 'tachoEvents', params: { serial: this.serial } }" tag="li" class="nav-item">
              <a class="nav-link px-3 text-center">Tacho events</a>
            </router-link>
            <router-link :to="{ name: 'massMemoryDls', params: { serial: this.serial } }" tag="li" class="nav-item">
              <a class="nav-link px-3 text-center">Mass Memory</a>
            </router-link>
            <router-link :to="{ name: 'appEvents', params: { serial: this.serial, ioFilter: null } }" tag="li" class="nav-item">
              <a class="nav-link px-3 text-center">App Events</a>
            </router-link>
            <router-link :to="{ name: 'ios', params: { serial: this.serial } }" tag="li" class="nav-item">
              <a class="nav-link px-3 text-center">IO</a>
            </router-link>
            <router-link :to="{ name: 'gpsStats', params: { serial: this.serial } }" tag="li" class="nav-item">
              <a class="nav-link px-3 text-center">GPS Stats</a>
            </router-link>
            <router-link :to="{ name: 'fmsEvents', params: { serial: this.serial } }" tag="li" class="nav-item">
              <a class="nav-link px-3 text-center">FMS</a>
            </router-link>
            <router-link :to="{ name: 'voltages', params: { serial: this.serial } }" tag="li" class="nav-item">
              <a class="nav-link px-3 text-center">Voltages</a>
            </router-link>
            <router-link :to="{ name: 'temperatures', params: { serial: this.serial } }" tag="li" class="nav-item">
              <a class="nav-link px-3 text-center">Temperatures</a>
            </router-link>
            <router-link :to="{ name: 'fuelLevels', params: { serial: this.serial } }" tag="li" class="nav-item">
              <a class="nav-link px-3 text-center">Fuel</a>
            </router-link>
          </ul>
          <form class="form-inline mt-2 mt-md-0">
            <router-link v-if="isSuperUser()" :to="{ name: 'deviceCreation'}" tag="button" class="btn btn-primary">Add</router-link>
            <button class="btn btn-outline-info my-2 my-sm-0 ml-1" @click="logout()">Logout</button>
          </form>
        </div>
      </nav>
    </header>
    <main>
      <keep-alive include="deviceSelection">
        <router-view/>
      </keep-alive>
    </main>
    <transition name="fade">
      <div v-show="showAlertBox" @click="showAlertBox = false" :class="['alert', 'alert-box', alertType]">{{ alertBoxText }}</div>
    </transition>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'

export default {
  data () {
    return {
      serial: 'none',
      showAlertBox: false,
      alertType: null,
      alertBoxText: '',
      timeout: null
    }
  },
  computed: {
    displayNav () {
      // we hide the navbar on the login page
      return (this.$route.name !== 'login')
    }
  },
  methods: {
    logout () {
      localStorage.removeItem('token')
      this.$router.push({ name: 'login' })
    },
    isSuperUser () {
      return (localStorage.getItem('role') === 'super_user')
    }
  },
  created () {
    // register listeners super early
    EventBus.$on('serial', (serial) => {
      this.serial = serial
    })
    EventBus.$on('alert', (level, text) => {
      switch (level) {
        case 'error':
          this.alertType = 'alert-danger'
          text = '🚨 ' + text
          break
        case 'info':
          text = '💬 ' + text
          this.alertType = 'alert-primary'
          break
      }
      this.alertBoxText = text
      this.showAlertBox = true
      // Auto-hide box after 5 seconds
      if (this.timeout !== null) {
        // clear previous timeout first
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => { this.showAlertBox = false }, 5000)
    })
  }
}
</script>

<style>
body {
  padding-top: 70px;
}
@media (min-width: 995px) and (max-width: 1435px) {
  body {
    padding-top: 110px;
  }
}
@media (max-width: 768px) {
  body {
    padding-top: 70px;
  }
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.alert-box {
  position: fixed;
  top: 80px;
  right: 15px;
  margin-left: 15px;
  cursor: pointer;
  font-size: 1.5em;
  z-index: 2000;
}
.logo {
  height: 28px;
}
.active .nav-link {
  border-radius: .25rem;
  background-color: #007bff;
  color: white !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
