<template>
  <div>
    <ViewHeader :serial="$route.params.serial" :hideDatePicker="true"/>
    <div v-if="loading" class="row">
      <div class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div v-if="noData" class="col-12 align-self-center text-center no-data">
          <h1>No data ¯\_(ツ)_/¯ </h1>
          <h3 class="no-data-serial">{{ this.serial }}</h3>
        </div>
        <div v-else class="col-12">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
                <tr>
                  <th scope="col">DI</th>
                  <th scope="col"></th>
                  <th scope="col">Application event</th>
                  <th scope="col">Date</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(event, k) in events" :key="k">
                  <td>{{ k }}</td>
                  <td>{{ event.noData ? '' : event.state === 1 ? event.high_character : event.low_character }}</td>
                  <td>{{ event.noData ? 'No data' : event.event }}</td>
                  <td>{{ event.noData ? '' : latestAppeventStr(event.date) }}</td>
                  <td><button v-if="!event.noData" class="btn btn-outline-primary text-nowrap" @click="gotoAppeventView(k, event)">View app events</button></td>
                  <td class="loading-row"><LoadingAnimation class="loading" v-show="event.loading" /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center">
            <button class="btn btn-primary mx-1 my-1" @click="loadLatestAppeventForDIs(events)">Refresh 🔄</button>
            <button :class="['btn auto-refresh-btn mx-1 my-1', autoRefreshBtnType]" @click="toggleAutoRefresh()">{{ autoRefreshBtnValue }}</button>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import ViewHeader from '@/components/ViewHeader.vue'
import axios from '@/axios-auth.js'
import { format } from 'timeago.js'

export default {
  data () {
    return {
      loading: true,
      events: {},
      serial: '',
      noData: false,
      autoRefreshInterval: null,
      autoRefreshBtnType: 'btn-secondary',
      autoRefreshBtnValue: 'Enable auto refresh'
    }
  },
  methods: {
    gotoAppeventView (di, event) {
      this.$router.push({
        name: 'appEvents',
        params: {
          serial: this.serial,
          ioFilter: {
            di: di,
            charHigh: event.high_character,
            charLow: event.low_character,
            ae: [event.high_application_event_id, event.low_application_event_id]
          }
        }
      })
    },
    loadEventMacthingRules () {
      this.loading = true

      axios.get('/device/' + this.serial + '/ios')
        .then((response) => {
          this.noData = false
          this.loadLatestAppeventForDIs(response.data).then(() => {
            this.events = response.data // only assign this.events now so that vue hooks on all added properties
            this.loading = false
          })
        })
        .catch((e) => {
          this.loading = false
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.1')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else if (e.response.status === 404) {
            this.noData = true
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the IO matching rules. Please try again later.')
            this.$router.push({ name: 'deviceSelection' })
          }
        })
    },
    loadLatestAppEvent (event) {
      return new Promise((resolve) => {
        event.loading = true

        axios.get('/device/' + this.serial + '/appevents', {
          params: {
            from: new Date(new Date().setFullYear(new Date().getFullYear() - 1)), // get event in the last year
            max: 1,
            ae: [event.high_application_event_id, event.low_application_event_id]
          }
        })
          .then((response) => {
            event.event = response.data[0].event
            event.date = response.data[0].date
            event.state = response.data[0].id === event.high_application_event_id ? 1 : 0
            event.noData = false
          })
          .catch((e) => {
            if (!e.response) {
              EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
            } else if (e.response.status === 401) {
              EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
              this.$router.push({ name: 'login' })
            } else if (e.response.status === 404) {
              event.noData = true
            } else {
              EventBus.$emit('alert', 'error', 'A server error occured while loading the application events. Please try again later.')
            }
          })
          .finally(() => {
            event.loading = false
            resolve()
          })
      })
    },
    loadLatestAppeventForDIs (events) {
      const promises = []
      for (var di in events) {
        promises.push(this.loadLatestAppEvent(events[di]))
      }
      return Promise.all(promises)
    },
    latestAppeventStr (date) {
      return new Date(date).toLocaleString() + ' (' + format(date) + ')'
    },
    startAutoRefresh () {
      this.autoRefreshInterval = setInterval(() => { this.loadLatestAppeventForDIs(this.events) }, 10 * 1000)
      this.autoRefreshBtnType = 'btn-primary'
      this.autoRefreshBtnValue = 'Disable auto refresh'
    },
    stopAutoRefresh () {
      if (this.autoRefreshInterval !== null) {
        clearInterval(this.autoRefreshInterval)
        this.autoRefreshInterval = null
      }
      this.autoRefreshBtnType = 'btn-secondary'
      this.autoRefreshBtnValue = 'Enable auto refresh'
    },
    toggleAutoRefresh () {
      if (!this.autoRefreshInterval) {
        this.startAutoRefresh()
      } else {
        this.stopAutoRefresh()
      }
    }
  },
  mounted () {
    this.serial = this.$route.params.serial
    // emit the serial in case it was set by the user in the url
    EventBus.$emit('serial', this.serial)
    this.loadEventMacthingRules()
  },
  components: {
    LoadingAnimation,
    ViewHeader
  }
}
</script>

<style scoped>
.no-data {
  margin-top: 20vh;
}
.no-data-serial {
  color:grey;
}
.loading {
  height: 16px;
}
.loading-row {
  width: 88px;
}
</style>
