<template>
  <div class="mb-2 row">
    <div class="col-md-5">
      <h4> {{ deviceName }} </h4>
    </div>
    <div v-if="!hideDatePicker" class="col-md-7">
      <form class="form-inline justify-content-end">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">From</div>
            </div>
            <flatPickr class="form-control date-width" :config="config" v-model="from" />
          </div>
          <div class="input-group my-2 my-lg-0 ml-sm-2">
            <div class="input-group-prepend">
              <div class="input-group-text">To</div>
            </div>
            <flatPickr class="form-control date-width" :config="config" v-model="to" />
          </div>
          <div class="input-group ml-sm-2">
            <button class="btn btn-primary form-control" @click.prevent="emitFromTo()" title="Get in selected range">➡️</button>
            <button class="btn btn-success form-control" @click.prevent="setFromTo(); emitFromTo()" title="Get latest">🔄</button>
          </div>
      </form>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import axios from '@/axios-auth.js'

export default {
  data () {
    return {
      deviceName: 'Loading ...',
      from: null,
      to: null,
      config: {
        time_24hr: true,
        enableTime: true,
        altInput: true,
        altFormat: 'Y-m-d H:i', // pretty format displayed to the user
        dateFormat: 'Z' // format used internally. return an ISO string so date parse works on all browsers
      }
    }
  },
  props: {
    serial: {
      type: String,
      required: true
    },
    range: {
      type: String,
      default: 'today'
    },
    hideDatePicker: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setDeviceName () {
      if (this.deviceName !== 'Loading ...') {
        return
      }

      // Look for the device name in sessionStorage
      // Otherwise fetch from server
      if (sessionStorage.getItem('serial') === this.serial) {
        this.deviceName = sessionStorage.getItem('deviceName')
        return
      }
      axios.get('/devices', {
        params: {
          search: this.serial
        }
      })
        .then((response) => {
          this.deviceName = response.data[0].name
          sessionStorage.setItem('serial', this.serial)
          sessionStorage.setItem('deviceName', this.deviceName)
        })
        .catch((e) => {
          this.deviceName = 'Failed to load device name :('
        })
    },
    setFromTo () {
      // Set inital from, to based on range string
      this.to = new Date()
      const f = new Date()

      if (this.range === 'today') {
        f.setDate(this.to.getDate() - 1)
      } else if (this.range === 'week') {
        f.setDate(this.to.getDate() - 7)
      } else if (this.range === 'month') {
        f.setMonth(this.to.getMonth() - 1)
      } else {
        // eslint-disable-next-line
        console.error('invalid range string')
      }

      this.from = f
    },
    emitFromTo () {
      // Signal our parent that a range was chosen
      // Date picker returns strings but we need dates
      // We need to be careful with the format of the date strings otherwise safari might fail to parse them
      // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
      this.$emit('picker', new Date(this.from), new Date(this.to))
    }
  },
  mounted () {
    this.setDeviceName()
    if (!this.hideDatePicker) {
      this.setFromTo()
      this.$nextTick(() => this.emitFromTo())
    }
  },
  components: {
    flatPickr
  }
}
</script>

<style scoped>
  .date-width {
    width: 160px;
  }
</style>
