<template>
  <div id="mapid"></div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import { Icon, map, tileLayer, control, marker } from 'leaflet'

// Leaflet icons issue, see
// https://github.com/KoRiGaN/Vue2Leaflet/issues/103
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

const token = process.env.VUE_APP_MAPBOX_TOKEN

export default {
  props: {
    lat: {
      type: Number
    },
    lon: {
      type: Number
    },
    zoom: {
      default: 13,
      type: Number
    }
  },
  methods: {
    getLastUsedLayer () {
      const layer = sessionStorage.getItem('mapLayer')
      if (layer === null) {
        // No saved layer, default to streets
        sessionStorage.setItem('mapLayer', 'Streets')
        return this.streets
      } else if (layer === 'Satellite') {
        return this.streetsSatellite
      } else {
        return this.streets
      }
    }
  },
  mounted () {
    const attribution = 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>'
    const mapboxUrl = 'https://api.mapbox.com/styles/v1/mapbox/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}'

    this.streets = tileLayer(mapboxUrl, {
      tileSize: 512,
      zoomOffset: -1,
      maxZoom: 20,
      id: 'streets-v11',
      attribution: attribution,
      accessToken: token
    })

    this.streetsSatellite = tileLayer(mapboxUrl, {
      tileSize: 512,
      zoomOffset: -1,
      maxZoom: 20,
      id: 'satellite-streets-v11',
      attribution: attribution,
      accessToken: token
    })

    const mymap = map('mapid', {
      center: [this.lat, this.lon],
      zoom: this.zoom,
      layers: [this.getLastUsedLayer()]
    })

    control.layers({
      Streets: this.streets,
      Satellite: this.streetsSatellite
    }).addTo(mymap)

    marker([this.lat, this.lon]).addTo(mymap)

    mymap.on('baselayerchange', (e) => {
      // Save selected layer
      sessionStorage.setItem('mapLayer', e.name)
    })
  }
}
</script>
