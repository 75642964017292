<template>
  <div>
    <ViewHeader :serial="$route.params.serial" :hideDatePicker="true"/>
    <configTabs/>
    <div v-if="loading" class="row">
      <div class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-sm-6 mb-2">
        <h3>Usecase</h3>
        <table class="table">
          <tbody>
            <tr>
              <th>{{ usecases.usecase.name }} ⭐</th>
              <td>{{ usecases.usecase.description }}</td>
              <td class="text-center">{{ usecases.usecase.up_id }}</td>
            </tr>
          </tbody>
        </table>
        <p v-if="!canChangeUC">
          <i>We don't support changing usecases for this device model through the technician website yet.<br>
          Contact <a :href="'mailto:support@ubidata.com?subject=Change usecase of device '+this.serial">support ✉️</a> if you want a different usecase.</i>
        </p>
        <div class="text-center">
          <button v-show="canChangeUC" class="btn btn-success mt-2" @click="saveUsecase()">Save usecase 💾</button>
        </div>
      </div>
      <div v-show="canChangeUC" class="col-sm-6 mb-2">
        <h3>Available usecases</h3>
        <div class="input-group mb-2">
            <input type="text" placeholder="filter usecases" class="form-control" v-model="filterText">
        </div>
        <table class="table">
          <tbody>
            <tr v-for="usecase in filteredUsecases" :key="usecase.up_id">
              <th>{{ usecase.name }}</th>
              <td>{{ usecase.description }}</td>
              <td class="text-center">{{ usecase.up_id }}</td>
              <td class="text-right"><button class="btn btn-primary" title="Set as usecase" @click="setUsecase(usecase)">⭐</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import ViewHeader from '@/components/ViewHeader.vue'
import axios from '@/axios-auth.js'
import ConfigTabs from '@/components/ConfigTabs.vue'

export default {
  data () {
    return {
      loading: true,
      usecases: {
        usecase: {},
        available_usecases: []
      },
      ogUsecase: 0,
      filterText: '',
      serial: '',
      canChangeUC: true
    }
  },
  methods: {
    loadDeviceUsecase () {
      this.loading = true
      axios.get('/device/' + this.serial + '/usecase')
        .then((response) => {
          this.usecases.usecase = response.data.usecase
          // save original usecase from response
          this.ogUsecase = this.usecases.usecase.up_id
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else if (e.response.status === 404) {
            // XXX what to do when no usecase ?
            this.usecases.usecase = { name: 'No usecase', description: '', up_id: 0 }
            this.ogUsecase = 0
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading device usecases. Please try again later.')
          }
        })
        .finally(() => {
          this.loadUsecases()
        })
    },
    loadUsecases () {
      axios.get('/device/' + this.serial + '/usecases')
        .then((response) => {
          // remove current usecase from usecases list to get availables
          this.usecases.available_usecases = response.data.filter(r => r.up_id !== this.ogUsecase)
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else if (e.response.status === 501) {
            this.canChangeUC = false
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading usecases. Please try again later.')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    sortUsecases (usecasesArray) {
      usecasesArray.sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
        if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
        return 0
      })
    },
    setUsecase (usecase) {
      // put old usecase back in available
      this.usecases.available_usecases.push(this.usecases.usecase)
      // set new usecase
      this.usecases.usecase = usecase
      // remove new usecase from available
      this.usecases.available_usecases = this.usecases.available_usecases.filter(r => r.up_id !== usecase.up_id)
      // sort modified available
      this.sortUsecases(this.usecases.available_usecases)
    },
    saveUsecase () {
      // check if anything has actually changed
      if (this.usecases.usecase.up_id === 0 || this.usecases.usecase.up_id === this.ogUsecase) {
        return
      }
      const payload = {}
      payload.usecase = this.usecases.usecase.up_id

      this.loading = true
      axios.put('/device/' + this.serial + '/usecase', payload)
        .then((response) => {
          // payload was successfully saved so it became our original usecase
          this.ogUsecase = payload.usecase
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while saving the device\'s usecase. Please try again later.')
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    filteredUsecases () {
      if (this.filterText.trim === '') {
        return this.usecases.available_usecases
      }
      return this.usecases.available_usecases.filter(p => {
        // filter on case insensitive usecase name or usecase id
        return p.name.toUpperCase().includes(this.filterText.toUpperCase()) || p.up_id.toString().includes(this.filterText)
      })
    }
  },
  mounted () {
    this.serial = this.$route.params.serial
    // emit the serial in case it was set by the user in the url
    EventBus.$emit('serial', this.serial)
    this.loadDeviceUsecase()
  },
  components: {
    LoadingAnimation,
    ViewHeader,
    ConfigTabs
  }
}
</script>
