<template>
  <div class="row">
    <div class="offset-sm-3 col-sm-6 offset-lg-4 col-lg-4 loginbox text-center">
      <img alt="Ubidata logo" src="@/assets/ubidata.svg" class="logo mb-1">
      <p class="subtitle">Technicians</p>
      <form>
        <div class="input-group mb-2 mr-sm-2">
          <div class="input-group-prepend">
            <div class="input-group-text">Username</div>
          </div>
          <input type="text" class="form-control" v-model="username" placeholder="Username" required>
        </div>
        <div class="input-group mb-2 mr-sm-2">
          <div class="input-group-prepend">
            <div class="input-group-text">Password</div>
          </div>
          <input type="password" class="form-control" v-model="password" placeholder="Password" required>
        </div>
        <button class="btn btn-primary form-control" @click.prevent="authenticate()" :disabled="loading">Login</button>
      </form>
      <div v-if="loading">
        <LoadingAnimation/>
      </div>
      <p v-else-if="wrongCredentials" class="text-danger mt-2">Wrong credentials</p>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import axios from 'axios'
import LoadingAnimation from '@/components/LoadingAnimation.vue'

export default {
  name: 'login',
  data () {
    return {
      loading: false,
      wrongCredentials: false,
      username: '',
      password: ''
    }
  },
  methods: {
    authenticate () {
      this.username = this.username.trim()
      this.password = this.password.trim()
      // username or password can't be blank
      if (this.username === '' || this.password === '') {
        return
      }
      this.loading = true
      axios.post(process.env.VUE_APP_API_BASE_URL + '/authenticate', {
        username: this.username,
        password: this.password
      })
        .then((response) => {
          localStorage.setItem('token', response.data.token)
          localStorage.setItem('role', response.data.role)
          localStorage.setItem('reseller_id', response.data.reseller_id)
          localStorage.setItem('project_id', response.data.project_id)
          this.$router.push('/')
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.wrongCredentials = true
          } else {
            EventBus.$emit('alert', 'error', 'We couldn\'t log you in. Please try again later.')
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  components: {
    LoadingAnimation
  }
}
</script>

<style scoped>
  .input-group-text {
    width: 100px;
  }
  .loginbox {
    margin-top: 5vh;
  }
  .logo {
    height: 100px;
  }
  .subtitle {
    font-style: italic;
    color: dimgray;
    font-size: 14px;
  }
</style>
