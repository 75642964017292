<script>
import { Line } from 'vue-chartjs'
export default {
  extends: Line,
  props: {
    chartData: {
      type: Array,
      required: false
    },
    chartTitle: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      options: {
        legend: {
          display: true
        },
        title: {
          display: false,
          text: ''
        },
        scales: {
          xAxes: [{
            type: 'time'
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    if (this.chartTitle !== '') {
      this.options.title.display = true
      this.options.title.text = this.chartTitle
    }
    this.renderChart({
      datasets: this.chartData
    }, this.options)
  }
}
</script>
