<template>
  <div>
    <ViewHeader :serial="$route.params.serial" @picker="pickerEventHandler" range="today"/>
    <div v-if="loading" class="row">
      <div class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
    </div>
    <div v-else>
      <div v-if="noSensorData && noInternalData" class="row">
        <div class="col-12 align-self-center text-center no-data">
          <h1>No data ¯\_(ツ)_/¯ </h1>
          <h3 class="text-secondary"> {{ this.serial }}</h3>
        </div>
      </div>
      <div v-else class="row mb-4">
        <div class="col-12">
          <LineChart :chart-data="values" chart-title="temperatures (°C)"/>
        </div>
      </div>
      <div v-if="!noSensors" class="row mb-4">
        <div class="col-12">
          <h3>Sensors</h3>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Address</th>
                <th scope="col">Zone</th>
                <th scope="col">Last temperature</th>
              </tr>
            </thead>
            <tbody>
              <tr :class="{'text-secondary': sensor.last_temperature_date === null}" v-for="sensor in sensors" :key="sensor.address">
                <th scope="row" class="mono">{{ sensor.address }}</th>
                <td><input type="number" class="form-control" v-model="sensor.zone"></td>
                <td>{{ sensor.last_temperature_date ? sensor.last_temperature_value + ' °C, ' + lastTemperatureStr(sensor.last_temperature_date) : 'No data' }}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-center">
            <button class="btn btn-success mt-2" @click="saveZones()">Save zones 💾</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import LineChart from '@/components/LineChart.vue'
import ViewHeader from '@/components/ViewHeader.vue'
import axios from '@/axios-auth.js'
import { format } from 'timeago.js'

export default {
  data () {
    return {
      loading: true,
      temperatures: {},
      sensors: [],
      ogSensors: [],
      values: [],
      serial: '',
      noSensorData: true,
      noInternalData: true,
      noSensors: false,
      from: null,
      to: null
    }
  },
  methods: {
    loadSensorTemperatures () {
      return new Promise((resolve) => {
        axios.get('/device/' + this.serial + '/temperatures', {
          params: {
            from: this.from,
            to: this.to,
            max: 100
          }
        })
          .then((response) => {
            this.temperatures = response.data
            for (const zone in this.temperatures) {
              this.organizeChartData(zone)
            }
            this.noSensorData = false
          })
          .catch((e) => {
            if (!e.response) {
              EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
            } else if (e.response.status === 401) {
              EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
              this.$router.push({ name: 'login' })
            } else if (e.response.status === 404) {
              this.noSensorData = true
            } else {
              EventBus.$emit('alert', 'error', 'A server error occured while loading the temperatures. Please try again later.')
              this.$router.push({ name: 'deviceSelection' })
            }
          })
          .finally(() => {
            resolve()
          })
      })
    },
    loadInternalTemperature () {
      return new Promise((resolve) => {
        axios.get('/device/' + this.serial + '/temperature/internal', {
          params: {
            from: this.from,
            to: this.to,
            max: 100
          }
        })
          .then((response) => {
            this.temperatures.internal = response.data
            this.organizeChartData('internal', true)
            this.noInternalData = false
          })
          .catch((e) => {
            if (!e.response) {
              EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
            } else if (e.response.status === 401) {
              EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
              this.$router.push({ name: 'login' })
            } else {
              EventBus.$emit('alert', 'error', 'A server error occured while loading the internal temperature. Please try again later.')
              this.$router.push({ name: 'deviceSelection' })
            }
          })
          .finally(() => {
            resolve()
          })
      })
    },
    loadSensors () {
      return new Promise((resolve) => {
        axios.get('/device/' + this.serial + '/temperature/sensors')
          .then((response) => {
            this.sensors = response.data.sort(this.compareSensors)
            this.ogSensors = JSON.parse(JSON.stringify(this.sensors)) // deep clone
            this.noSensors = false
          })
          .catch((e) => {
            if (!e.response) {
              EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
            } else if (e.response.status === 401) {
              EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
              this.$router.push({ name: 'login' })
            } else if (e.response.status === 404) {
              this.noSensors = true
            } else {
              EventBus.$emit('alert', 'error', 'A server error occured while loading the temperature sensors. Please try again later.')
              this.$router.push({ name: 'deviceSelection' })
            }
          })
          .finally(() => {
            resolve()
          })
      })
    },
    compareSensors (a, b) {
      // display the sensors with most recent data first
      if (a.last_temperature_date == null) {
        return 1
      } else if (b.last_temperature_date == null) {
        return -1
      } else {
        return a.last_temperature_date < b.last_temperature_date
      }
    },
    zonesChanged () {
      const payload = []
      const zones = []
      for (let i = 0; i < this.sensors.length; i++) {
        const zone = parseInt(this.sensors[i].zone)
        if (zone === parseInt(this.ogSensors[i].zone)) {
          zones.push(zone)
          continue // this zone wasn't changed
        }
        if (zone <= 0 || zone > 255) {
          EventBus.$emit('alert', 'info', 'Sensor zone must be between 1 and 255.')
          return null
        }
        if (zone !== 100 && zones.includes(zone)) {
          EventBus.$emit('alert', 'info', 'Sensor zone must be unique or 100.')
          return null
        }
        // zone was changed and is valid
        zones.push(zone)
        payload.push({
          zone: this.sensors[i].zone,
          address: this.sensors[i].address
        })
      }
      return payload
    },
    saveZones () {
      const payload = this.zonesChanged()
      if (payload === null /* error */ || payload.length === 0 /* no changes */) {
        return
      }
      this.loading = true
      axios.patch('/device/' + this.serial + '/temperature/sensors', payload)
        .then((response) => {
          this.ogSensors = JSON.parse(JSON.stringify(this.sensors)) // deep clone
          // reload temperatures with new zones
          this.loadTemperatures()
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
            return
          }
          EventBus.$emit('alert', 'error', 'A server error occured while saving the zones. Please try again later.')
          this.loading = false
        })
    },
    loadTemperatures () {
      this.loading = true
      this.values = []

      const promises = []
      promises.push(this.loadSensorTemperatures())
      promises.push(this.loadSensors())
      // only display the internal temperature of the device for super user accounts
      if ((localStorage.getItem('role') === 'super_user')) {
        promises.push(this.loadInternalTemperature())
      }

      Promise.all(promises).finally(() => { this.loading = false })
    },
    lastTemperatureStr (date) {
      return new Date(date).toLocaleString() + ' (' + format(date) + ')'
    },
    getMetricColor (metric) {
      switch (metric) {
        case 'zone1':
          return 'blue'
        case 'zone2':
          return 'red'
        case 'zone3':
          return 'green'
        case 'zone4':
          return 'yellow'
        case 'internal':
          return 'orange'
        default:
          return 'black'
      }
    },
    organizeChartData (metric, hidden = false) {
      const value = {
        label: metric,
        borderColor: this.getMetricColor(metric),
        fill: false,
        lineTension: 0,
        hidden: hidden,
        data: []
      }
      const l = this.temperatures[metric].length
      for (let i = 0; i < l; i++) {
        // reverse order while organizing
        value.data[l - 1 - i] = {
          x: new Date(this.temperatures[metric][i].date),
          y: this.temperatures[metric][i].value
        }
      }
      this.values.push(value)
    },
    pickerEventHandler (from, to) {
      this.from = from
      this.to = to
      this.loadTemperatures()
    }
  },
  mounted () {
    this.serial = this.$route.params.serial
    // emit the serial in case it was set by the user in the url
    EventBus.$emit('serial', this.serial)
  },
  components: {
    LoadingAnimation,
    LineChart,
    ViewHeader
  }
}
</script>

<style scoped>
.no-data {
  margin-top: 20vh;
}
.mono {
  font-family: monospace;
  font-size: 16px;
}
</style>
