<template>
  <div>
    <ViewHeader :serial="$route.params.serial" :hideDatePicker="true"/>
    <configTabs/>
    <div v-if="loading" class="row">
      <div class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12 offset-sm-3 col-sm-6 offset-lg-4 col-lg-4">
        <form>
          <div class="form-group">
            <label for="devname">Device name</label>
            <input type="text" class="form-control" id="devname" placeholder="Device name" v-model.trim="config.name">
          </div>
          <div class="form-group">
            <label for="userref">User reference</label>
            <input type="text" class="form-control" id="userref" placeholder="User reference" v-model.trim="config.user_ref">
          </div>
          <div class="form-group">
            <label for="installdate">Install date</label>
            <div class="input-group">
              <flatPickr class="form-control date-width" id="installdate" :config="pickerConfig" v-model="config.install_date" />
              <div class="input-group-append">
                <button class="btn btn-primary" @click.prevent="config.install_date = new Date()" title="Set installation date to now">Now</button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="logcat">Login category</label>
            <select class="form-control" id="logcat" v-model="device_login.category" @change="loadEntities()">
               <option value="nochoice"></option>
               <option v-for="c in loginCategories" :value="c.category" :key="c.category">
                  {{c.category}}
                </option>
            </select>
          </div>
          <div class="form-group">
            <label for="entname">Entity name</label>
            <select class="form-control" id="entname" v-model.number="device_login.entity">
               <option value="nochoice"></option>
               <option v-for="e in entities" :value="e.id" :key="e.id">
                  {{e.name}}
                </option>
            </select>
          </div>
          <div class="form-group">
            <label for="distance">KM</label>
            <input type="number" class="form-control" id="distance" min=0 v-model="config.km">
          </div>
          <div class="form-group">
            <label for="distdate">KM date</label>
            <div class="input-group">
              <flatPickr class="form-control date-width" id="distdate" :config="pickerConfig" v-model="config.km_date" />
              <div class="input-group-append">
                <button class="btn btn-primary" @click.prevent="config.install_date = new Date()" title="Set KM date to now">Now</button>
              </div>
            </div>
          </div>
          <div class="text-center">
            <button class="btn btn-success m-1" @click.prevent="saveModifiedDevice()">Save 💾</button>
            <button class="btn btn-warning m-1" @click.prevent="logoff()">Logoff</button>
          </div>
        </form>
      </div>
      <div v-if="isSuperUser()" class="col-12 offset-sm-3 col-sm-6 offset-lg-4 col-lg-4">
        <hr>
        <div class="text-center">
          <button class="btn btn-danger m-1" @click="deleteDevice()">Delete device</button>
          <button class="btn btn-warning m-1" @click="setActiveStatus()">{{ config.active ? 'Deactivate device' : 'Activate device' }}</button>
        </div>
      </div>
    </div>
    <div v-if="mode.canSwitch" class="row justify-content-center">
      <button class="btn btn-primary m-1" @click="switchMode()" :disabled="mode.disableSwitchButton">{{ 'Switch to ' + modeType[mode.new]}}</button>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import ViewHeader from '@/components/ViewHeader.vue'
import axios from '@/axios-auth.js'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import ConfigTabs from '@/components/ConfigTabs.vue'

export default {
  data () {
    return {
      loading: true,
      mode: {
        canSwitch: false,
        current: 2, // unavailable to switch mode by default
        new: 2,
        disableSwitchButton: false
      },
      config: {
        install_date: null, // must already exist for v-model to work
        active: true,
        km_date: null
      },
      ogConfig: {},
      serial: '',
      pickerConfig: {
        time_24hr: true,
        enableTime: true,
        altInput: true,
        altFormat: 'Y-m-d H:i', // pretty format displayed to the user
        dateFormat: 'Z' // format used internally. return an ISO string so date parse works on all browsers
      },
      modeType: {
        0: 'Operationnal',
        1: 'Installer',
        2: 'Unavailable'
      },
      loginCategories: null,
      entities: null,
      device_login: {
        category: 'nochoice',
        entity: 'nochoice'
      },
      ogLogin: {}
    }
  },
  methods: {
    loadDeviceDetails () {
      this.loading = true
      axios.get('/device/' + this.serial)
        .then((response) => {
          // only keep the properties we need from response.data
          this.config.name = response.data.name
          this.config.user_ref = response.data.user_ref
          this.config.install_date = response.data.install_date
          this.config.active = response.data.active
          this.config.km = response.data.km_input
          this.config.km_date = response.data.km_input_date
          this.ogConfig = JSON.parse(JSON.stringify(this.config)) // deep clone
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else if (e.response.status === 404) {
            EventBus.$emit('alert', 'error', 'The server couldn\'t find the device details for serial ' + this.serial)
            this.$router.push({ name: 'deviceSelection' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the device details. Please try again later.')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    apiPatchRequest (payload) {
      this.loading = true
      axios.patch('/device/' + this.serial, payload)
        .then((response) => {
          // payload was successfully saved so it became our original config
          for (const property in payload) {
            this.ogConfig[property] = payload[property]
          }
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while saving the device config. Please try again later.')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveModifiedDevice () {
      const payload = {}
      let changes = false

      if (this.config.name !== this.ogConfig.name) {
        payload.name = this.config.name
        changes = true
      }

      if (this.config.user_ref !== this.ogConfig.user_ref) {
        payload.user_ref = this.config.user_ref
        changes = true
      }

      if (this.config.install_date !== this.ogConfig.install_date) {
        payload.install_date = new Date(this.config.install_date)
        changes = true
      }

      if (this.config.km !== this.ogConfig.km) {
        payload.km_input = this.config.km
        changes = true
      }

      if (this.config.km_date !== this.ogConfig.km_date) {
        payload.km_input_date = new Date(this.config.km_date)
        changes = true
      }

      if (changes) {
        this.apiPatchRequest(payload)
      }

      if (this.device_login.category !== 'nochoice' && this.device_login.entity !== 'nochoice') {
        this.updateLogin()
      }
    },
    setActiveStatus () {
      if (!confirm('Are you sure ?')) {
        return
      }
      const payload = {}
      this.config.active = !this.config.active // toggle current active status
      payload.active = this.config.active
      this.apiPatchRequest(payload)
    },
    deleteDevice () {
      if (!confirm('Are you sure ?')) {
        return
      }

      this.loading = true
      axios.delete('/device/' + this.serial)
        .then((response) => {
          EventBus.$emit('alert', 'info', 'Device ' + this.serial + ' was deleted.')
          this.$router.push({ name: 'deviceSelection' })
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while deleting the device. Please try again later.')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    isSuperUser () {
      return (localStorage.getItem('role') === 'super_user')
    },
    getCurrentMode () {
      axios.get('/device/' + this.serial + '/mode')
        .then((response) => {
          this.mode.current = response.data
          if (this.mode.current === 1) {
            this.mode.new = 0
          } else if (this.mode.current === 0) {
            this.mode.new = 1
          }
          this.mode.canSwitch = this.mode.current !== 2
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the device details. Please try again later.')
          }
        })
    },
    switchMode () {
      this.mode.disableSwitchButton = true // prevent requesting another switch
      const payload = {}
      payload.mode = this.mode.new
      axios.put('/device/' + this.serial + '/mode', payload)
        .then((response) => {
          EventBus.$emit('alert', 'info', 'Switching device to ' + this.modeType[this.mode.new])
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while switching device mode. Please try again later.')
          }
        })
    },
    loadLoginCategories () {
      axios.get('/entity/categories')
        .then((response) => {
          this.loginCategories = response.data
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while switching device mode. Please try again later.')
          }
        })
    },
    loadEntities () {
      if (this.device_login.category === 'nochoice') {
        this.entities = null
        this.device_login.entity = 'nochoice'
      } else {
        axios.post('/device/' + this.serial + '/entities', { category: this.device_login.category })
          .then((response) => {
            this.entities = response.data
          })
          .catch((e) => {
            if (!e.response) {
              EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
            } else if (e.response.status === 401) {
              EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
              this.$router.push({ name: 'login' })
            } else {
              EventBus.$emit('alert', 'error', 'A server error occured while switching device mode. Please try again later.')
            }
          })
      }
    },
    loadLoginInfo () {
      axios.get('/device/' + this.serial + '/login')
        .then((response) => {
          if (response.status === 200) {
            this.device_login.category = response.data.category
            this.device_login.entity = response.data.Entityid
          } else {
            this.device_login.category = 'nochoice'
            this.device_login.entity = 'nochoice'
          }
          this.ogLogin = JSON.parse(JSON.stringify(this.device_login)) // deep clone
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while switching device mode. Please try again later.')
          }
        })
        .finally(() => {
          this.loadLoginCategories()
          this.loadEntities()
        })
    },
    updateLogin () {
      const payload = {}
      payload.category = this.device_login.category
      payload.entityId = this.device_login.entity
      payload.date = this.config.install_date

      axios.put('/device/' + this.serial + '/login', payload)
        .then((response) => {
          EventBus.$emit('alert', 'info', 'Device logged in with ' + this.entities.find(e => e.id === this.device_login.entity).name)
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 400) {
            EventBus.$emit('alert', 'error', e.response.data.message)
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while switching device mode. Please try again later.')
          }
        })
    },
    logoff () {
      if (!confirm('Are you sure ?')) {
        return
      }
      axios.put('/device/' + this.serial + '/logoff')
        .then((response) => {
          EventBus.$emit('alert', 'info', 'Device logged off')
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while switching device mode. Please try again later.')
          }
        })
    }
  },
  mounted () {
    this.serial = this.$route.params.serial
    // emit the serial in case it was set by the user in the url
    EventBus.$emit('serial', this.serial)
    this.loadDeviceDetails()
    this.getCurrentMode()
    this.loadLoginInfo()
  },
  components: {
    LoadingAnimation,
    ViewHeader,
    flatPickr,
    ConfigTabs
  }
}
</script>
