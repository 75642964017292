import Vue from 'vue'
import Router from 'vue-router'
import EventBus from '@/eventbus.js'

import Login from './views/Login.vue'
import DeviceSelection from './views/DeviceSelection.vue'
import DeviceDetails from './views/DeviceDetails.vue'
import TachoEvents from './views/TachoEvents.vue'
import MassMemoryDls from './views/MassMemoryDls.vue'
import AppEvents from './views/AppEvents.vue'
import IOs from './views/IOs.vue'
import GpsStats from './views/GpsStats.vue'
import DeviceProjects from './views/DeviceProjects.vue'
import FMSEvents from './views/FMSEvents.vue'
import Voltages from './views/Voltages.vue'
import Temperatures from './views/Temperatures.vue'
import FuelLevels from './views/FuelLevels.vue'
import DeviceResellers from './views/DeviceResellers.vue'
import DeviceUsecases from './views/DeviceUsecases.vue'
import DeviceConfig from './views/DeviceConfig.vue'
import BleSensors from './views/BleSensors.vue'
import GprsCommands from './views/GprsCommands.vue'
import DeviceCreation from './views/DeviceCreation.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: {
        name: 'deviceSelection'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/devices',
      name: 'deviceSelection',
      component: DeviceSelection
    },
    {
      path: '/device/:serial',
      name: 'deviceDetails',
      component: DeviceDetails
    },
    {
      path: '/device/:serial/tachoevents',
      name: 'tachoEvents',
      component: TachoEvents
    },
    {
      path: '/device/:serial/massmemorydls',
      name: 'massMemoryDls',
      component: MassMemoryDls
    },
    {
      path: '/device/:serial/appevents',
      name: 'appEvents',
      component: AppEvents
    },
    {
      path: '/device/:serial/ios',
      name: 'ios',
      component: IOs
    },
    {
      path: '/device/:serial/gpsstats',
      name: 'gpsStats',
      component: GpsStats
    },
    {
      path: '/device/:serial/projects',
      name: 'deviceProjects',
      component: DeviceProjects
    },
    {
      path: '/device/:serial/fmsevents',
      name: 'fmsEvents',
      component: FMSEvents
    },
    {
      path: '/device/:serial/voltages',
      name: 'voltages',
      component: Voltages
    },
    {
      path: '/device/:serial/temperatures',
      name: 'temperatures',
      component: Temperatures
    },
    {
      path: '/device/:serial/fuellevels',
      name: 'fuelLevels',
      component: FuelLevels
    },
    {
      path: '/device/:serial/resellers',
      name: 'deviceResellers',
      component: DeviceResellers
    },
    {
      path: '/device/:serial/usecases',
      name: 'deviceUsecases',
      component: DeviceUsecases
    },
    {
      path: '/device/:serial/config',
      name: 'deviceConfig',
      component: DeviceConfig
    },
    {
      path: '/device/:serial/wireless_sensors',
      name: 'bleSensors',
      component: BleSensors
    },
    {
      path: '/device/:serial/gprs',
      name: 'gprsCommands',
      component: GprsCommands
    },
    {
      path: '/create',
      name: 'deviceCreation',
      component: DeviceCreation
    },
    {
      // not found catch-all
      path: '*',
      redirect: '/'
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !localStorage.getItem('token')) {
    next({ name: 'login' })
  } else if (to.params.serial === 'none') {
    EventBus.$emit('alert', 'info', 'Select a device first')
    next({ name: 'deviceSelection' })
  } else {
    next()
  }
})

export default router
