<template>
  <div>
    <ViewHeader :serial="$route.params.serial" :hideDatePicker="true"/>
    <configTabs/>
    <div v-if="loading" class="row">
      <div class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
    </div>
    <div v-else class="col-12 offset-sm-3 col-sm-6 offset-lg-4 col-lg-4">
        <div>
            <textarea class="form-control fs-5" v-model="history" readonly rows="16"></textarea>
        </div>
        <div class="text-center">
            <button class="btn btn-primary m-1" @click="sendCmd('getinfo')">getinfo</button>
            <button class="btn btn-primary m-1" @click="sendCmd('getstatus')">getstatus</button>
            <button class="btn btn-primary m-1" @click="sendCmd('getgps')">getgps</button>
            <button class="btn btn-primary m-1" @click="sendCmd('getver')">getver</button>
        </div>
        <div class="text-center">
            <button class="btn btn-primary m-1" @click="sendCmd('web_connect')">web_connect</button>
            <button class="btn btn-primary m-1" @click="sendCmd('tacho_connect')">tacho_connect</button>
        </div>
        <div class="text-center">
            <button class="btn btn-warning m-1" @click="reset()">cpureset</button>
        </div>
        <p class="text-center">
            List of commands format available <a href="https://wiki.teltonika-gps.com/view/FMB204_SMS/GPRS_Commands" target="_blank">here</a>
        </p>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import ViewHeader from '@/components/ViewHeader.vue'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import axios from '@/axios-auth.js'
import ConfigTabs from '@/components/ConfigTabs.vue'

export default {
  data () {
    return {
      loading: true,
      history: 'Command history',
      polling: null
    }
  },
  methods: {
    getHistory () {
      axios.get('/device/' + this.serial + '/gprsrsp')
        .then((response) => {
          this.history = response.data.rsp.join('\n')
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else if (e.response.status !== 404) {
            EventBus.$emit('alert', 'error', 'A server error occured while reading command history. Please try again later.')
            this.$router.push({ name: 'deviceSelection' })
          }
        })
    },
    sendCmd (cmdString) {
      const payload = {}
      payload.command = cmdString
      axios.put('/device/' + this.serial + '/gprscmd', payload)
        .then((response) => {
          EventBus.$emit('alert', 'info', 'Sending ' + cmdString + ' to ' + this.serial)
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while sending command. Please try again later.')
          }
        })
    },
    reset () {
      if (confirm('Reset device?')) {
        this.sendCmd('cpureset')
      }
    }
  },
  mounted () {
    this.serial = this.$route.params.serial
    // emit the serial in case it was set by the user in the url
    EventBus.$emit('serial', this.serial)
    this.getHistory()
    // setup polling
    this.polling = setInterval(() => { this.getHistory() }, 5 * 1000)
    // redirected from another tab to send a command
    if (this.$route.params.pendingCommand) {
      this.sendCmd(this.$route.params.pendingCommand)
    }
    this.loading = false
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  components: {
    LoadingAnimation,
    ViewHeader,
    ConfigTabs
  }
}
</script>

<style scoped>
.col-small {
  width: 10%;
}

.col-large {
  width: 30%;
}
</style>
