<template>
  <div>
    <ViewHeader :serial="$route.params.serial" @picker="pickerEventHandler" range="today"/>
    <div class="row mb-2">
      <div class="col-12">
        <div class="d-flex">
          <input type="text" placeholder="Filter shown application events" class="form-control mr-2" v-model="appeventLocalFilter" @input="globalChecked = false">
          <button class="btn btn-success text-nowrap" @click="selectionBtnHandler()">{{ appeventsModal ? 'Save selection' : 'Select app events' }}</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-if="loading > 0" class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
      <div v-else-if="appeventsModal" class="col-12">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead class="thead-light">
              <tr>
                <th scope="col" style="width: 6%"><input type="checkbox" v-model="globalChecked" @click="checkboxHandler()"></th>
                <th scope="col" style="width: 47%">Category</th>
                <th scope="col" style="width: 47%">Event</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="event in filteredAppeventKeys" :key="event.id">
                <td><input type="checkbox" v-model="selected[event.id]"></td>
                <td>{{ event.category }}</td>
                <td>{{ event.event }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="col-12">
        <div v-if="ioFilter" class="alert alert-primary alert-dismissible show mb-2" role="alert">
          <b>Showing IO</b> : DI {{ ioFilter.di }} ({{ioFilter.charHigh}} / {{ioFilter.charLow}})
          <button type="button" class="close" data-dismiss="alert" @click="removeIoFilter()" title="Remove IO filter">
            <span>&times;</span>
          </button>
        </div>
        <div v-if="noData" class="align-self-center text-center no-data">
          <h1>No data ¯\_(ツ)_/¯ </h1>
          <h3 class="no-data-serial"> {{ this.serial }}</h3>
        </div>
        <div v-else class="table-responsive">
          <table class="table table-striped">
            <thead class="thead-light">
              <tr>
                <th scope="col">Event</th>
                <th scope="col">Value</th>
                <th scope="col">Info</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="event in filteredAppevents" :key="event.uid">
                <td>{{ event.event }}</td>
                <td>{{ event.value }}</td>
                <td>{{ event.info }}</td>
                <td>{{ new Date(event.date).toLocaleString() }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import ViewHeader from '@/components/ViewHeader.vue'
import axios from '@/axios-auth.js'

export default {
  data () {
    return {
      ioFilter: null,
      selected: {},
      allSelected: false,
      globalChecked: false,
      appeventsModal: false,
      loading: 0, // we load multiple things concurrently
      events: [],
      eventKeys: [],
      serial: '',
      noData: false,
      appeventFilter: [],
      appeventLocalFilter: '',
      from: null,
      to: null
    }
  },
  methods: {
    removeIoFilter () {
      this.ioFilter = null
      this.setAppeventFilter(this.selected)
      this.loadAppevents()
    },
    checkboxHandler () {
      this.filteredAppeventKeys.forEach((e) => { this.selected[e.id] = !this.globalChecked })
    },
    setAppeventFilter (selected) {
      this.appeventFilter = []
      this.allSelected = true
      // find all selected event key id's
      for (const id in selected) {
        if (selected[id]) {
          this.appeventFilter.push(id)
        } else {
          this.allSelected = false
        }
      }
    },
    selectionBtnHandler () {
      this.appeventsModal = !this.appeventsModal
      this.appeventLocalFilter = ''
      this.globalChecked = false
      this.ioFilter = null
      if (!this.appeventsModal) {
        // save selection
        localStorage.setItem('appeventSelected', JSON.stringify(this.selected))
        this.setAppeventFilter(this.selected)
        this.loadAppevents()
      }
    },
    loadAppeventKeys (appeventSelected) {
      this.loading++

      axios.get('/appevents')
        .then((response) => {
          // use saved selection value if available or default to true
          response.data.forEach((e) => { this.selected[e.id] = appeventSelected[e.id] !== undefined ? appeventSelected[e.id] : true })
          this.eventKeys = response.data
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the application event keys. Please try again later.')
            this.$router.push({ name: 'deviceSelection' })
          }
        })
        .finally(() => {
          this.loading--
        })
    },
    loadAppevents () {
      this.loading++

      axios.get('/device/' + this.serial + '/appevents', {
        params: {
          from: this.from,
          to: this.to,
          max: 100,
          ae: !this.ioFilter && this.allSelected ? [] : this.appeventFilter // no need to send a filter if we want everything
        }
      })
        .then((response) => {
          // add a uid to response to make v-for key happy
          let i = 0
          this.events = response.data.map((e) => {
            e.uid = i++
            return e
          })
          this.noData = false
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else if (e.response.status === 404) {
            this.noData = true
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the application events. Please try again later.')
            this.$router.push({ name: 'deviceSelection' })
          }
        })
        .finally(() => {
          this.loading--
        })
    },
    pickerEventHandler (from, to) {
      this.from = from
      this.to = to
      this.loadAppevents()
    }
  },
  computed: {
    filteredAppevents () {
      const f = this.appeventLocalFilter.trim().toUpperCase()
      if (f === '') {
        return this.events
      }
      return this.events.filter(e => e.event.includes(f))
    },
    filteredAppeventKeys () {
      const f = this.appeventLocalFilter.trim().toUpperCase()
      if (f === '') {
        return this.eventKeys
      }
      return this.eventKeys.filter(e => e.event.includes(f) || e.category.includes(f))
    }
  },
  mounted () {
    this.serial = this.$route.params.serial
    // emit the serial in case it was set by the user in the url
    EventBus.$emit('serial', this.serial)
    let appeventSelected = {}
    if (localStorage.getItem('appeventSelected')) {
      // load saved selection if available
      appeventSelected = JSON.parse(localStorage.getItem('appeventSelected'))
      this.setAppeventFilter(appeventSelected)
    }
    this.loadAppeventKeys(appeventSelected)
    // highjack the appevent filter if io filter is set
    this.ioFilter = this.$route.params.ioFilter
    if (this.ioFilter) {
      this.appeventFilter = this.ioFilter.ae
    }
  },
  components: {
    LoadingAnimation,
    ViewHeader
  }
}
</script>

<style scoped>
.no-data {
  margin-top: 20vh;
}
.no-data-serial {
  color:grey;
}
</style>
