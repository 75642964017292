<template>
  <div>
    <ViewHeader :serial="$route.params.serial" :hideDatePicker="true"/>
    <configTabs/>
    <div v-if="loading" class="row">
      <div class="col-12 align-self-center text-center">
        <LoadingAnimation/>
      </div>
    </div>
    <div v-else>
      <div class="row mb-4">
        <div class="col-12">
          <h3>BLE Sensors</h3>
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="col-small">Type</th>
                <th scope="col" class="col-large">Address</th>
                <th scope="col" class="col-large">Name</th>
                <th scope="col" class="col-small">Index</th>
                <th scope="col" class="col-small">Zone</th>
                <th class="col-small"></th>
              </tr>
            </thead>
            <tbody>
              <tr :class="{'text-muted': !sensor.active}" v-for="sensor in sensors" :key="sensor.address">
                <td>{{mapType[sensor.type]}}</td>
                <td>{{sensor.address.toString(16).toUpperCase()}}</td>
                <td>{{sensor.name}}</td>
                <td>{{sensor.index}}</td>
                <td>{{sensor.zone}}</td>
                <td><button class="btn btn-danger" v-if="sensor.active" @click="deleteSensor(sensor)">Del</button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- we cannot have more than 4 active sensors -->
        <div v-if="sensors.filter((a) => a.active).length < 4"  class="col-12">
          <table class="table">
            <td scope="col" class="col-small">
              <select class="form-control" v-model.number="newSensor.type">
                <option v-for="(sensName, sensType) in mapType" :value="sensType" :key="sensType">
                  {{sensName}}
                </option>
              </select>
            </td>
            <td scope="col" class="col-large"><input type="text" class="form-control" placeholder="ex. 1F28E4320D4A" minlength="12" maxlength="12" v-model="newSensor.address"></td>
            <td scope="col" class="col-large"><input type="text" class="form-control" placeholder="ex. P T 80A02E" minlength="3" v-model="newSensor.name"></td>
            <td scope="col" class="col-small"><input type="number" class="form-control" min="1" max="4" v-model.number="newSensor.index"></td>
            <td scope="col" class="col-small"><input type="text" class="form-control" v-if="newSensor.type == 0" v-model="newSensor.zone"></td>
            <td scope="col" class="col-small"><button class="btn btn-success" @click="saveSensor()">Add</button></td>
          </table>
        </div>
        <div v-if="canSendConfig()" class="col-12 offset-sm-3 col-sm-6 offset-lg-4 col-lg-4">
          <button class="btn btn-primary m-1" @click="gotoGprs()">Send config</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventbus.js'
import ViewHeader from '@/components/ViewHeader.vue'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import axios from '@/axios-auth.js'
import ConfigTabs from '@/components/ConfigTabs.vue'

export default {
  data () {
    return {
      loading: true,
      sensors: [],
      newSensor: {
        address: null,
        name: null,
        type: null,
        index: null
      },
      mapType: {
        0: 'T°',
        1: 'Door'
      }
    }
  },
  methods: {
    loadSensors () {
      axios.get('/device/' + this.serial + '/wireless_sensors')
        .then((response) => {
          // sort data by index asc
          this.sensors = response.data.sort(this.compareSensors)
          // reset form if success
          for (var key in this.newSensor) {
            this.newSensor[key] = null
          }
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else if (e.response.status !== 404) {
            EventBus.$emit('alert', 'error', 'A server error occured while loading the wireless sensors. Please try again later.')
            this.$router.push({ name: 'deviceSelection' })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveSensor () {
      // type must be set
      if (this.newSensor.type == null) {
        EventBus.$emit('alert', 'error', 'Invalid type.')
        return
      }
      // address must be hexadecimal string without :
      const regexp = /^[0-9a-fA-F]+$/
      if ((!regexp.test(this.newSensor.address)) || (this.newSensor.address.length !== 12)) {
        EventBus.$emit('alert', 'error', 'Invalid address.')
        return
      }
      // validate name
      if (this.newSensor.name.length < 3) {
        EventBus.$emit('alert', 'error', 'Invalid name. Must be at least 3 characters long.')
        return
      }
      // validate index
      if ((this.newSensor.index == null) || (this.newSensor.index < 0) || (this.newSensor.index > 4)) {
        EventBus.$emit('alert', 'error', 'Invalid index. Must be between 1 and 4.')
        return
      }
      // zone must be set for Temp sensors
      if (this.newSensor.type === 0 && this.newSensor.zone == null) {
        EventBus.$emit('alert', 'error', 'Zone missing.')
        return
      }
      var sensorToAdd = JSON.parse(JSON.stringify(this.newSensor)) // deep copy
      sensorToAdd.address = parseInt(sensorToAdd.address, 16)
      axios.post('/device/' + this.serial + '/wireless_sensor', sensorToAdd)
        .then((response) => {
          // sensor succesfully created
          // XXX add success messages to emit
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while creating the sensor. Please try again later.')
          }
        })
        .finally(() => {
          this.loadSensors()
        })
    },
    compareSensors (a, b) {
      if (b.active === a.active) {
        return a.index - b.index
      } else {
        return b.active - a.active
      }
    },
    deleteSensor (s) {
      if (!confirm('Are you sure you want to delete sensor ' + s.name + '?')) {
        return
      }
      var sensorToDelete = {
        address: s.address
      }
      axios.delete('/device/' + this.serial + '/wireless_sensor', { data: sensorToDelete })
        .then((response) => {
          EventBus.$emit('alert', 'info', 'Sensor ' + s.name + ' was deleted.')
        })
        .catch((e) => {
          if (!e.response) {
            EventBus.$emit('alert', 'error', 'The server is unavailable. Please try again later.')
          } else if (e.response.status === 401) {
            EventBus.$emit('alert', 'info', 'Your session has expired. Please login again.')
            this.$router.push({ name: 'login' })
          } else {
            EventBus.$emit('alert', 'error', 'A server error occured while deleting the device. Please try again later.')
          }
        })
        .finally(() => {
          this.loadSensors()
        })
    },
    gotoGprs () {
      this.$router.push({
        name: 'gprsCommands',
        params: {
          serial: this.serial,
          pendingCommand: 'configble'
        }
      })
    },
    canSendConfig () {
      // this is a Teltonika device and there is at least 1 active sensor
      return ((sessionStorage.getItem('deviceModel').toLowerCase().indexOf('teltonika') >= 0) &&
              (this.sensors.filter((a) => a.active).length > 0))
    }
  },
  mounted () {
    this.serial = this.$route.params.serial
    // emit the serial in case it was set by the user in the url
    EventBus.$emit('serial', this.serial)
    this.loadSensors()
  },
  components: {
    LoadingAnimation,
    ViewHeader,
    ConfigTabs
  }
}
</script>

<style scoped>
.col-small {
  width: 10%;
}

.col-large {
  width: 30%;
}
</style>
